import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
  doc,
  collection,
  getDocs,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import Modal from "./Modal";

const MyHunt = () => {
  //TODO: Filtrera bort jakter som inte är aktiva.
  const [selected, setSelected] = useState({});
  const [hunts, setHunts] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { user, getUserProfile, userProfileData } = UserAuth();

  useEffect(() => {
    try {
      getUserProfile(user.uid).then((res) => {});
    } catch (error) {}
  }, [user]);

  const getAvaliableHunts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "jakter"));
      let collectedHunts = [];
      querySnapshot.docs.map((h) => {
        if (h.data().active) {
          collectedHunts.push({ id: h.id, ...h.data() });
        }
        return setHunts(collectedHunts);
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getAvaliableHunts();
  }, []);

  const addUserToHunt = async (jaktId) => {
    // console.log(
    //   "jaktId: " + jaktId + "userprofile: " + userProfileData.firstName
    // );
    try {
      const huntRef = doc(db, "jakter", jaktId);
      await updateDoc(huntRef, {
        participants: arrayUnion({ active: false, ...userProfileData }),
      });
      setIsDialogOpen(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSelectHunt = () => {
    if (selected.id !== undefined) {
      addUserToHunt(selected.id);
    } else {
      console.log("Välj en jakt.");
    }
  };

  return (
    <div className="max-w-[800px] mx-auto my-4 px-4">
      <Modal
        open={isDialogOpen}
        handleCloseDialog={() => {
          setIsDialogOpen(false);
        }}
        buttonFunction={() => {
          //navigate("/jakter");
          setIsDialogOpen(false);
        }}
        text="Du har nu anmält ditt intresse till en jakt. Blir du uttagen, kommer du få ett mail eller samtal ifrån jaktledaren med mer information."
        header="Intresseanmälan"
        buttonText="OK"
      />
      <h2 className="text-center font-bold text-lg">Intresseanmälan jakt</h2>

      <div className="flex flex-col my-4">
        <p>
          För att deltaga i en jakt krävs det att du har jägarexamen och ett
          hagelgevär. Vi brukar rekommendera US 3 or 38gr. Du behöver vara helt
          kamouflerad, använda handskar, buff/ansiktsskydd och huvudbonad.
          Absolut inget oranget. Tänk på att gässen har extremt bra syn. Du kan
          se nedan när en jakt är planerad. Vill du delta, väljer du en och
          klickar på knappen.
        </p>
        <p>
          Du får se det som en intresseanmälan då det ofta är många som vill
          jaga och platserna få. Vi försöker få till att så många som möjligt
          ska få chansen och prioriterar de som inte deltagit tidigare. Blir du
          uttagen kommer du få ett mail med mer information om var vi träffas
          och hur jakten kommer gå till. Jaktledaren kommer berätta mer på
          plats. Bra att veta är att vi alltid använder hundar till apportering
          och eftersök. Det är ofta kort framförhållning när vi lägger ut en ny
          jaktmöjlighet.
        </p>
      </div>
      <div className="w-full pt-2">
        <p className="font-bold mt-4">
          Här kan du välja en jakt du vill anmäla ditt intresse till.
        </p>
        <p className="font-bold pb-2">
          Efter anmält intresse hör vi av oss till dig om blir uttagen.
        </p>

        <div className="mx-auto w-full">
          <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only">
              Tillgängliga jakter
            </RadioGroup.Label>
            <div className="space-y-2">
              {hunts.map((h) => (
                <RadioGroup.Option
                  key={h.name}
                  value={h}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                        : ""
                    }
                  ${
                    checked ? "bg-sky-900 bg-opacity-75 text-white" : "bg-white"
                  }
                    relative flex cursor-pointer rounded px-5 py-4 shadow-md focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium  ${
                                checked ? "text-white" : "text-gray-900"
                              }`}
                            >
                              {h.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked ? "text-sky-100" : "text-gray-500"
                              }`}
                            >
                              <span>
                                {h.date}{" "}
                                <span aria-hidden="true">&middot;</span>{" "}
                                <span className="font-thin">Kl:</span> {h.time}
                              </span>{" "}
                              <span aria-hidden="true">&middot;</span>{" "}
                              <span className="font-thin">Samlingsplats:</span>{" "}
                              <span>{h.place}</span>
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <div className="shrink-0 text-white">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <button
            disabled={selected.id === undefined}
            onClick={handleSelectHunt}
            className="border disabled:bg-gray-400  border-sky-300 bg-sky-800 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded"
          >
            Välj jakt
          </button>
        </div>
      </div>
    </div>
  );
};
function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default MyHunt;
