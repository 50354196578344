import React, { useState } from "react";
import goose from "../assets/goose.svg";
import { sendPasswordResetEmail } from "firebase/auth";
import Modal from "./Modal";
import { auth } from "../firebase";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const triggerResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(
        "Det har skickats ett meddelande via e-post till dig med vidare instruktioner. Kolla i din e-post och säkerställ att det inte lagt sig i din skräppost."
      );
      setShowModal(true);
    } catch (error) {
      setMessage(
        "Något gick fel. Säkerställ att du angivit den epost adress du skapade kontot med."
      );
      setShowModal(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    triggerResetEmail();
    try {
    } catch (error) {
      setMessage(
        "Något gick fel. Säkerställ att du angivit den epost adress du skapade kontot med."
      );
    }
  };

  return (
    <div className="max-w-[800px] mx-auto my-4 p-4">
      <div className="flex flex-col justify-center items-center mb-4">
        <img className="w-[50%] mb-16" src={goose} alt="bild av gäss" />

        <p className="text-center text-lg font-bold">Återställ ditt lösenord</p>
        <p>
          Fyll i din epostadress som du använde när du skapade ditt konto så
          skickas det instruktioner för att återställa ditt lösenord.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">E-post</label>
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="email"
            required
          />
        </div>
        <button className="border border-sky-300 bg-sky-700 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded">
          Återställ Lösenord
        </button>
        <div className="py-4">
          <a className="font-medium text-white hover:text-sky-700" href="/">
            Till start sidan
          </a>
        </div>
      </form>
      <Modal
        open={showModal}
        handleCloseDialog={() => {
          setShowModal(false);
        }}
        buttonFunction={() => {
          setShowModal(false);
        }}
        text={message}
        header="Återställ lösenord"
        buttonText="OK"
      />
    </div>
  );
};

export default ResetPassword;
