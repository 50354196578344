import React from "react";

const Checkbox = ({ id, label, value, checked, onChange, name }) => {
  return (
    <div>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        value={value}
        name={name}
        className="w-4 h-4 text-sky-800 bg-gray-100 rounded border-gray-300 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label
        htmlFor={id}
        className="ml-2 text-sm font-medium text-gray-800 dark:text-gray-500"
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
