import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { UserAuth } from "../context/AuthContext";

import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const { logout, user } = UserAuth();
  const navigate = useNavigate();
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const handleLogout = async () => {
    // if (!userIsLoggedIn) navigate("/");
    try {
      await logout();
      setUserIsLoggedIn(false);
      navigate("/");
      console.log("You are logged out");
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (user.auth) setUserIsLoggedIn(true);
  }, [user]);

  return (
    <div className="w-full text-white h-20 flex justify-between items-center p-2">
      <h1 className="text-xl font-bold p-4">
        <a href="/">Hullsjö Gåsjakt</a>
      </h1>
      {!userIsLoggedIn ? (
        <></>
      ) : (
        <ul className="flex items-center">
          <li className="p-2">
            <a
              className="capital text-sm font-thin hover:underline underline-offset-4"
              href="/account"
            >
              Mitt konto
            </a>
          </li>
          <li className="p-2">
            <a
              className="capital text-sm font-thin hover:underline underline-offset-4"
              href="/jakter"
            >
              Jakter
            </a>
          </li>

          <li className="p-2">
            <p
              onClick={handleLogout}
              className="capital text-sm hover:underline underline-offset-4 cursor-pointer"
            >
              Logga ut
            </p>
          </li>

          {user.admin ? (
            <li>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full rounded border border-sky-300 shadow-sm px-4 py-2 bg-sky-800 text-sm font-medium text-white hover:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    Admin
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/skapajakt"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Skapa jakt
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <>
                            {/* <Link to="/hanterajakt" className='underline'> Logga in här.</Link> */}
                            <a
                              href="/hanterajakt"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Hantera jakt
                            </a>
                          </>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/admin"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Hantera Admin
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
          ) : (
            <></>
          )}
        </ul>
      )}
    </div>
  );
};

export default Navbar;
