import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { setDoc, doc } from "firebase/firestore";
import Modal from "./Modal";
import Checkbox from "./Checkbox";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { createUser } = UserAuth();
  const { user } = UserAuth();

  useEffect(() => {
    if (user) {
      // navigate("/account");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (password !== password2) {
      setMessage("Lösenorden måste matcha.");
      setShowModal(true);
      return;
    }
    if (!checked) {
      setMessage("Du måste godkänna villkoren för att skapa ett konto");
      setShowModal(true);
      return;
    }

    const setUserProfile = (uid) => {
      try {
        const userData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phoneNumber,
          consent:
            "Genom att skapa ett konto, godkänner jag och ger mitt samtycke till att mina personuppgifter lagras och används för anordnande och administration av samt i övrigt i samband med de jakter som erbjuds. Inga uppgifter kommer att säljas vidare eller medvetet spridas till tredje part. Kontakta oss på hullsjojakt@gmail.com om du vill att dina personuppgifter raderas eller rättas, om du i övrigt vill ta del av dina uppgifter eller hur vi använder dessa, om du vill flytta dina uppgifter eller begränsa hur de används. Vill du framföra klagomål kan du kontakta oss på ovan angiven adress. Du kan även kontakta Integritetskyddsmyndigheten, se www.imy.se för kontaktuppgifter.",
        };

        const docRef = doc(db, "users", uid);
        setDoc(docRef, userData).then(() => {});
      } catch (error) {
        console.log(error.message);
      }
    };

    try {
      await createUser(email, password).then((cred) => {
        setUserProfile(cred.user.uid);
      });
      navigate("/jakter");
    } catch (err) {
      setError(err.message);
      setMessage(
        "Epost adressen finns redan. Prova att logga in med befintig e-postadress"
      );
      setShowModal(true);
      console.log(err.message);
    }
  };

  return (
    <div className="max-w-[800px] mx-auto my-4 p-4">
      <div>
        <h1 className="text-2xl font-bold py-2">
          Skapa ett konto kostnadsfritt.
        </h1>
        <p className="py-2">
          Har du redan ett konto?
          <Link to="/" className="underline">
            {" "}
            Logga in här.
          </Link>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Förnamn</label>
          <input
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="input"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Efternamn</label>
          <input
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="input"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Mobilnummer</label>
          <input
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="number"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">E-post</label>
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="email"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Lösenord</label>
          <input
            onChange={(p) => {
              setPassword(p.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="password"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Upprepa lösenord</label>
          <input
            onChange={(p) => {
              setPassword2(p.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="password"
          />
        </div>
        <Checkbox
          id="checkbox_consent"
          name="checkbox_consent"
          value={
            "Genom att skapa ett konto, godkänner jag och ger mitt samtycke till att mina personuppgifter lagras och används för anordnande och administration av samt i övrigt i samband med de jakter som erbjuds. Inga uppgifter kommer att säljas vidare eller medvetet spridas till tredje part. Kontakta oss på hullsjojakt@gmail.com om du vill att dina personuppgifter raderas eller rättas, om du i övrigt vill ta del av dina uppgifter eller hur vi använder dessa, om du vill flytta dina uppgifter eller begränsa hur de används. Vill du framföra klagomål kan du kontakta oss på ovan angiven adress. Du kan även kontakta Integritetskyddsmyndigheten, se www.imy.se för kontaktuppgifter."
          }
          text="Jag godkänner att ni lagrar mina personuppgifter"
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
          label="* Jag godkänner att ni sparar mina personuppgifter"
        ></Checkbox>
        <p className="text-xxs font-light">
          * Genom att skapa ett konto, godkänner jag och ger mitt samtycke till
          att mina personuppgifter lagras och används för anordnande och
          administration av samt i övrigt i samband med de jakter som erbjuds.
          Inga uppgifter kommer att säljas vidare eller medvetet spridas till
          tredje part. Kontakta oss på hullsjojakt@gmail.com om du vill att dina
          personuppgifter raderas eller rättas, om du i övrigt vill ta del av
          dina uppgifter eller hur vi använder dessa, om du vill flytta dina
          uppgifter eller begränsa hur de används. Vill du framföra klagomål kan
          du kontakta oss på ovan angiven adress. Du kan även kontakta
          Integritetskyddsmyndigheten, se www.imy.se för kontaktuppgifter.
        </p>
        <button className="border border-sky-300 bg-sky-700 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded">
          Skapa konto och logga in
        </button>
      </form>
      <Modal
        open={showModal}
        handleCloseDialog={() => {
          setShowModal(false);
        }}
        buttonFunction={() => {
          setShowModal(false);
        }}
        text={message}
        header="Något stämmer inte"
        buttonText="OK"
      />
    </div>
  );
};

export default Signup;
