import React from "react";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Account from "./components/Account";
import { Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import MyHunt from "./components/MyHunt";
import Navbar from "./components/Navbar";
import CreateHunt from "./components/CreateHunt";
import EditHunts from "./components/EditHunts";
import Test from "./components/Test";
import AddUserToAdmin from "./components/AddUserToAdmin";
import ResetPassword from "./components/ResetPassword";

function App() {
  return (
    <div>
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/jakter" element={<MyHunt />} />
          <Route path="/skapajakt" element={<CreateHunt />} />
          <Route path="/hanterajakt" element={<EditHunts />} />
          <Route path="/admin" element={<AddUserToAdmin />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route
            path="/account"
            element={
              // <ProtectedRoute>
              <Account />
              // </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
