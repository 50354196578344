import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

const Account = () => {
  const { user, getUserProfile } = UserAuth();
  const [userProfile, setUserProfile] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    try {
      getUserProfile(user.uid).then((res) => {
        setUserProfile(res);
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setPhone(res.phone);
        setEmail(res.email);
      });
    } catch (error) {}
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateDocument(db);
    setShowEdit(false);
  };

  const onCancelChanges = () => {
    setFirstName(userProfile.firstName);
    setLastName(userProfile.lastName);
    setPhone(userProfile.phone);
  };

  const updateDocument = async (db) => {
    // Uppdatera ändringar.
    try {
      const data = {
        firstName,
        lastName,
        phone,
      };
      const docRef = doc(db, "users", user.uid);
      setDoc(docRef, data, { merge: true }).then(() => {
        console.log("Uppgifterna uppdaterad");
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="max-w-[800px] mx-auto my-4 px-4 text-gray-50">
      <div className="w-full items-center px-4">
        {!showEdit ? (
          <>
            <h1 className="text-xl font-medium py-4">Mina uppgifter</h1>
            <div className="flex flex-col pb-1">
              <label className="font-medium">Namn</label>
              <p className="font-light">
                {firstName} {lastName}
              </p>
            </div>
            <div className="flex flex-col pb-1">
              <label className="font-medium">E-post</label>
              <p className="font-light">{email}</p>
            </div>
            <div className="flex flex-col pb-1">
              <label className="font-medium">Telefon</label>
              <p className="font-light">{phone}</p>
            </div>
            {user.admin ? (
              <div className="flex flex-col pb-1">
                <label className="font-medium">Rättigheter</label>
                <p className="font-light">Admin</p>
              </div>
            ) : (
              <p></p>
            )}
            <div className="flex flex-col pt-4 max-w-[200px]">
              <button
                onClick={() => {
                  setShowEdit(true);
                }}
                className="border disabled:bg-gray-400  border-sky-300 bg-sky-700 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded"
              >
                Ändra mina uppgifter
              </button>
            </div>
          </>
        ) : (
          <>
            <form className="text-black">
              <div className="flex flex-col py-2 ">
                <label className="p-2 font-medium">Förnamn</label>
                <input
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  className="border p-3"
                  type="input"
                  value={firstName}
                />
              </div>
              <div className="flex flex-col py-2">
                <label className="p-2 font-medium">Efternamn</label>
                <input
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  className="border p-3"
                  type="input"
                  value={lastName}
                />
              </div>
              <div className="flex flex-col py-2">
                <label className="p-2 font-medium">Mobilnummer</label>
                <input
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  className="border p-3"
                  type="number"
                  value={phone}
                />
              </div>
              <div className="flex flex-col py-2">
                <label className="p-2 font-medium">E-post</label>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="border p-3"
                  type="email"
                  value={email}
                  disabled
                />
              </div>
              <button
                onClick={handleSubmit}
                className="border border-sky-300 bg-sky-700 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded"
              >
                Spara mina ändringar
              </button>
            </form>
            <button
              onClick={onCancelChanges}
              className="border border-sky-300 bg-sky-700 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded"
            >
              Avbryt
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Account;
