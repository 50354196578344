import React, { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { Switch } from "@headlessui/react";
import Checkbox from "./Checkbox";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";

const EditHunt = ({ hunt, getHunts }) => {
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [time, setTime] = useState("");
  const [place, setPlace] = useState("");
  const [date, setDate] = useState("");
  const [participants, setParticipants] = useState([{}]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const navigate = useNavigate();

  const documentId = hunt.id;
  useEffect(() => {
    setName(hunt.name);
    setActive(hunt.active);
    setTime(hunt.time);
    setDate(hunt.date);
    setPlace(hunt.place);
    setParticipants(hunt.participants);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateDocument(db);
    setModalText("Jakten är uppdaterad");
    setIsDialogOpen(true);
    navigate("/hanterajakt");
  };

  const updateDocument = async (db) => {
    // Uppdatera jakten med ändringar.
    try {
      let jaktData = {
        name: name,
        active: active,
        time: time,
        place: place,
        date: date,
      };
      if (participants) {
        jaktData = { participants: participants, ...jaktData };
      }
      const docRef = doc(db, "jakter", documentId);
      setDoc(docRef, jaktData).then(() => {
        console.log("jakten uppdaterad");
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCheckStatus = (user) => {
    setParticipants(
      participants.map((p) =>
        p.uid !== user.uid ? p : { ...p, active: !p.active }
      )
    );
  };

  const copyEmail = () => {
    function copyToClipboard(e) {
      e.preventDefault();
      let text = "";

      text = participants.map((p) => {
        let epostadresser = "";
        if (p.email && p.active) {
          epostadresser += p.email;
        }
        return epostadresser;
      });
      //ta bort fnutt
      text = text.toString().replaceAll(",", "; ");
      if (text.charAt(text.length - 1) === ";") {
        text = text.slice(0, -1);
      }
      if (text.toString().startsWith(";")) {
        text = text.substring(1);
      }
      text = text.replaceAll(";;", ";");

      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.setAttribute("value", text);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      setModalText(
        "E-postadresserna är nu kopierade och du kan kopiera in dem i adressfälet när du skapar ett mail."
      );
      setIsDialogOpen(true);
    }

    return (
      <div className="max-w-sm">
        <button
          className="border disabled:bg-gray-400  border-sky-300 bg-sky-800 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-2 my-2 text-white rounded"
          onClick={copyToClipboard}
        >
          Kopiera e-postadresser till urklipp
        </button>
        <Modal
          open={isDialogOpen}
          handleCloseDialog={() => {
            setIsDialogOpen(false);
          }}
          buttonFunction={() => {
            setIsDialogOpen(false);
          }}
          text={modalText}
          header=""
          buttonText="OK"
        />
      </div>
    );
  };

  return (
    <div className="max-width[700px] mx-auto my-4 p-4">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <Switch.Group>
            <div className="flex flex-col py-2 ml-3 ">
              <Switch.Label className="mr-4 mb-2">
                Gör jakten anmälningsbar och synlig för deltagarna
              </Switch.Label>
              <Switch
                checked={active}
                onChange={setActive}
                className={`${
                  active ? "bg-sky-700" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
              >
                <span
                  className={`${
                    active ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Namn på jakten</label>
          <input
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="border p-3"
            type="text"
            name="name"
            value={name}
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Datum</label>
          <input
            onChange={(e) => {
              setDate(e.target.value);
            }}
            className="border p-3"
            type="text"
            name="date"
            value={date}
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Tid för samling</label>
          <input
            onChange={(e) => {
              setTime(e.target.value);
            }}
            className="border p-3"
            type="text"
            name="time"
            value={time}
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Samlingsplats</label>
          <input
            onChange={(e) => {
              setPlace(e.target.value);
            }}
            className="border p-3"
            type="text"
            name="place"
            value={place}
          />
        </div>
        <div className="flex flex-col py-2">
          <h3>Deltagare</h3>
          <p>
            Klicka i dem som kommer deltaga i jakten och glöm inte att spara!
          </p>

          {participants &&
            participants.map((p, index) => (
              <div key={p.uid + index}>
                <Checkbox
                  id="1"
                  label={
                    p.firstName +
                    " " +
                    p.lastName +
                    ", Tel: " +
                    p.phone +
                    ", E-post: " +
                    p.email
                  }
                  checked={participants[index].active}
                  value={p}
                  name={`checkbox-${index}`}
                  onChange={() => {
                    handleCheckStatus(p);
                  }}
                />
              </div>
            ))}
          {copyEmail()}
        </div>
        <div className="flex flex-col py-2">
          <button className="border disabled:bg-gray-400  border-sky-300 bg-sky-800 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded">
            Spara ändringarna
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditHunt;
