import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Tab } from "@headlessui/react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import EditHunt from "./EditHunt";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EditHunts = () => {
  const [hunts, setHunts] = useState([]);
  const [selected, setSelected] = useState({});

  const getAvaliableHunts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "jakter"));
      let collectedHunts = [];
      querySnapshot.docs.map((h) => {
        collectedHunts.push({ id: h.id, ...h.data() });
        return setHunts(collectedHunts);
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getAvaliableHunts();
  }, []);

  return (
    <div className="max-w-[800px] mx-auto my-4 px-4">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded bg-blue-900/20 p-1">
          <Tab
            key={"jakter"}
            className={({ selected }) =>
              classNames(
                "w-full rounded py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-sky-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            <p>Jakter</p>
          </Tab>
          <Tab
            key="hantera"
            disabled={selected.id === undefined}
            className={({ selected }) =>
              classNames(
                "w-full rounded py-2.5 text-sm font-medium leading-5 text-sky-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-sky-100 disabled:text-gray-600 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            {selected.name !== undefined ? (
              <p>{`Editera ${selected.name}`}</p>
            ) : (
              <p>{"Ingen jakt vald"}</p>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel
            key={1}
            className={classNames(
              "rounded bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-700 bg-slate-50 focus:outline-none focus:ring-2"
            )}
          >
            {/* <h2 className="text-center font-bold text-lg"> Hantera jakter</h2> */}
            <p className="font-bold mt-2">
              Välj en jakt för att välja gäster, editera eller ta bort.
            </p>
            <div className="w-full px-4 py-2">
              <div className="mx-auto w-full">
                <RadioGroup value={selected} onChange={setSelected}>
                  <RadioGroup.Label className="sr-only">
                    Tillgängliga jakter
                  </RadioGroup.Label>
                  <div className="space-y-2">
                    {hunts.map((h) => (
                      <RadioGroup.Option
                        key={h.name}
                        value={h}
                        className={({ active, checked }) =>
                          `${
                            active
                              ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                              : ""
                          }
                  ${
                    checked ? "bg-sky-900 bg-opacity-75 text-white" : "bg-white"
                  }
                    relative flex cursor-pointer rounded px-5 py-4 shadow-md focus:outline-none`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="flex w-full items-center justify-between">
                              <div className="flex items-center">
                                <div className="text-sm">
                                  <RadioGroup.Label
                                    as="p"
                                    className={`font-medium  ${
                                      checked ? "text-white" : "text-gray-900"
                                    }`}
                                  >
                                    {h.name}
                                    {h.active ? "" : " (Ej aktiv)"}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={`inline ${
                                      checked ? "text-sky-100" : "text-gray-500"
                                    }`}
                                  >
                                    <span>
                                      {h.date}{" "}
                                      <span aria-hidden="true">&middot;</span>{" "}
                                      <span className="font-thin">Kl:</span>{" "}
                                      {h.time}
                                    </span>{" "}
                                    <span aria-hidden="true">&middot;</span>{" "}
                                    <span className="font-thin">
                                      Samlingsplats:
                                    </span>{" "}
                                    <span>{h.place}</span>
                                  </RadioGroup.Description>
                                </div>
                              </div>
                              {checked && (
                                <div className="shrink-0 text-white">
                                  <CheckIcon className="h-6 w-6" />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
                {/* <button
                  disabled={selected.id === undefined}
                  onClick={onButtonClick}
                  className="border disabled:bg-gray-400  border-sky-300 bg-sky-700 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded-lg"
                >
                  Välj jakt{" "}
                </button> */}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel
            key={2}
            className={classNames(
              "rounded bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
            )}
          >
            <p>Hantera jakt</p>
            <EditHunt hunt={selected} getHunts={getAvaliableHunts} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default EditHunts;

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
