import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const AddUserToAdmin = () => {
  const [usersEmail, setUsersEmail] = useState([]);

  useEffect(() => {
    getUserEmails();
  }, []);

  const getUserEmails = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      let collectedEmails = [];
      querySnapshot.docs.map((u) => {
        collectedEmails.push(u.data().email);
        return setUsersEmail(collectedEmails);
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  //add admin cloud function
  const addAdmin = (e) => {
    e.preventDefault();
    const value = e.target[0].value;
    const functions = getFunctions();
    const addMessage = httpsCallable(functions, "addAdminRole");
    try {
      addMessage({ email: value }).then((result) => {
        const data = result.data;
        const sanitizedMessage = data.text;
        console.log(data);
        console.log(sanitizedMessage);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  //remove admin cloud function
  const removeAdmin = (e) => {
    e.preventDefault();
    const value = e.target[0].value;
    const functions = getFunctions();
    const addMessage = httpsCallable(functions, "removeAdminRole");
    try {
      addMessage({ email: value }).then((result) => {
        const data = result.data;
        const sanitizedMessage = data.text;
        console.log(data);
        console.log(sanitizedMessage);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const copyToClipboard = (data) => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = data;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  const copyAllEmails = () => {
    copyToClipboard(usersEmail);
  };

  return (
    <>
      <form
        onSubmit={addAdmin}
        className="flex flex-col justify-center items-center mb-4"
      >
        <div className="flex flex-col py-2 w-4/6 ">
          <p className="font-bold pb-2 text-left">
            Fyll i en användares e-post adress för att lägga till användaren
            till admin gruppen.
          </p>
          <input
            className="border p-3 rounded w-full"
            type="email"
            placeholder="User Email"
            id="admin-email"
            required
          ></input>

          <button className="border disabled:bg-gray-400  border-sky-300 bg-sky-800 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded">
            Gör användare till Admin
          </button>
        </div>
      </form>
      <form
        onSubmit={removeAdmin}
        className="flex flex-col justify-center items-center mb-4"
      >
        <div className="flex flex-col py-2 w-4/6">
          <p className="font-bold pb-2 text-left">
            Fyll i en användares e-post adress för att ta bort användaren som
            admin.
          </p>

          <input
            className="border p-3 rounded"
            type="email"
            placeholder="User Email"
            id="admin-email"
            required
          ></input>

          <button className="border disabled:bg-gray-400  border-sky-300 bg-sky-800 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded">
            Ta bort användaren som admin
          </button>
        </div>
      </form>
      <form className="flex flex-col justify-center items-center mb-4">
        <div className="flex flex-col py-2 w-4/6">
          <p className="font-bold pb-2 text-left">
            Kopiera alla användares epostadresser. Dessa sparas i urklipp och
            kan sedan kopieras in i t ex ett mail.
          </p>{" "}
          <button
            className="border disabled:bg-gray-400  border-sky-300 bg-sky-800 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded"
            onClick={copyAllEmails}
          >
            Kopiera alla användares e-postadresser
          </button>
        </div>
      </form>
    </>
  );
};

export default AddUserToAdmin;
