import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { UserAuth } from "../context/AuthContext";

const plans = [
  {
    name: "Startup",
    ram: "12GB",
    cpus: "6 CPUs",
    disk: "160 GB SSD disk",
  },
  {
    name: "Business",
    ram: "16GB",
    cpus: "8 CPUs",
    disk: "512 GB SSD disk",
  },
  {
    name: "Enterprise",
    ram: "32GB",
    cpus: "12 CPUs",
    disk: "1024 GB SSD disk",
  },
];

const Test = () => {
  const [selected, setSelected] = useState(plans[0]);
  const { user, getUserProfile, userProfileData } = UserAuth();
  return (
    <div className="max-w-[800px] mx-auto my-4 px-4">
      {/* <a className='text-sm underline font-light' href={'/account'}>Tillbaka</a> */}
      <h2 className="text-center font-bold text-lg">Intresseanmälan jakt</h2>

      {/* <div className='flex justify-between '>
        <div className='justify-start'>
            <p className='font-light'>Konto</p>
            <p className='font-light text-sm'>{user && user.displayName}</p>
            <p className='font-light text-sm'>{user && user.email}</p>
        </div>
        <div className='justify-end'><p><button onClick={handleLogout} className='border px-4 py-1 y-2'>Logga ut</button></p></div>
        </div> */}
      <div className="flex flex-col my-4">
        <p>
          Att du anmäler dig till jakt innebär inte att du automatiskt blir
          uttagen eller att jakten blir av. Detta beror på ett högt intresse
          samt att gässen helt plötsligt kan ha flyttat på sig. Du kan endast
          anmäla ett intresse, vi kommer höra av oss till de som blivit uttagna
          till en jakt. Vi försöker få med så många som möjligt under året och
          blir du inte uttagen denna gång så anmäl dig tills gång. Vi försöker
          fördela platserna och har du inte varit med tidigare under året så
          kommer du ha förtur till nästa. Kom i håg, vi gör så gott vi kan och
          vill att så många som möjligt får chansen till att jaga gås.
        </p>
        <p className="font-bold mt-4">
          Här kan du välja i den jakt du vill anmäla ditt intresse till.
        </p>
        <p className="font-bold">
          Efter anmält intresse hör vi av oss till dig som blir uttagen
        </p>
      </div>
      <div className="w-full px-4 py-16">
        <div className="mx-auto w-full max-w-md">
          <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
            <div className="space-y-2">
              {plans.map((plan) => (
                <RadioGroup.Option
                  key={plan.name}
                  value={plan}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                        : ""
                    }
                  ${
                    checked ? "bg-sky-900 bg-opacity-75 text-white" : "bg-white"
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium  ${
                                checked ? "text-white" : "text-gray-900"
                              }`}
                            >
                              {plan.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked ? "text-sky-100" : "text-gray-500"
                              }`}
                            >
                              <span>
                                {plan.ram}/{plan.cpus}
                              </span>{" "}
                              <span aria-hidden="true">&middot;</span>{" "}
                              <span>{plan.disk}</span>
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <div className="shrink-0 text-white">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};
function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default Test;
