// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDWWUdjAYOYtK0r69csTurx_cfAu9EHS6g",
  authDomain: "hullsjogasjakt.firebaseapp.com",
  projectId: "hullsjogasjakt",
  storageBucket: "hullsjogasjakt.appspot.com",
  messagingSenderId: "1085904002061",
  appId: "1:1085904002061:web:3258dd941e3584b302c944",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;
