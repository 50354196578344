import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import goose from "../assets/goose.svg";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { user, signIn } = UserAuth();

  useEffect(() => {
    if (user) {
      // navigate("account");
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signIn(email, password);
      console.log("logged in");
      navigate("/jakter");
      //navigate("account");
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className="max-w-[800px] mx-auto mb-16 p-4">
      {/* REMOVE THIS BEFORE PUBLISH */}
      <div className="flex flex-col justify-center items-center mb-4">
        <img className="w-[50%] mb-16" src={goose} alt="bild av gäss" />

        <p className="text-center text-lg font-bold">
          Välkommen till Hullsjö Gåsjakt
        </p>
        <p>
          Att jaga gäss med bulvaner är både en rolig och actionfylld jaktform
          som vi tycker fler ska få ta del av. Vi hjälper bönderna och markägare
          i trakterna runt Hullsjön med både skyddsjakt och jakt. Är du
          intresserad? Skapa ett konto och logga in.
        </p>
      </div>
      <div>
        <h1 className="text-2xl font-bold py-2">Logga in till ditt konto</h1>
        <p className="py-2">
          Har du inget konto?
          <Link to="/signup" className="underline">
            {" "}
            Skapa ett här.
          </Link>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">E-post</label>
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="email"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Lösenord</label>
          <input
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="border border-sky-700 rounded p-3"
            type="password"
          />
        </div>
        <button className="border border-sky-300 bg-sky-700 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded">
          Logga in
        </button>
      </form>
      <p>
        Vid andra frågor eller hjälp med åtkomst till kontot, maila till{" "}
        <a href="mailto:hullsjojakt@gmail.com">Hullsjojakt</a> eller använd
        länken{" "}
        <a className="text-white hover:text-sky-600" href="resetpassword">
          återställ lösenord
        </a>
      </p>
    </div>
  );
};

export default SignIn;
