import {} from "@firebase/util";
import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Switch } from "@headlessui/react";
import Modal from "./Modal";

const CreateHunt = () => {
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [place, setPlace] = useState("");
  const [active, setActive] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const docRef = await addDoc(collection(db, "jakter"), {
        name,
        date,
        time,
        place,
        active,
      });
      console.log("Jakt skapad med ID: ", docRef.id);
      setDate("");
      setName("");
      setPlace("");
      setTime("");
      setActive(false);
    } catch (er) {
      //om inte detta fungerar tetsa att använda callback function , err => {console.log(rr.message)}
      console.log(er.message);
    }
    setIsDialogOpen(true);
  };

  return (
    <div className="w-full items-center px-8">
      <form className="" onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <Switch.Group>
            <div className="flex flex-col py-2 ml-3 ">
              <Switch.Label className="mr-4 mb-2">Aktiv</Switch.Label>
              <Switch
                checked={active}
                onChange={setActive}
                className={`${
                  active ? "bg-sky-700" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
              >
                <span
                  className={`${
                    active ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Jaktens namn</label>
          <input
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            className="border border-sky-700 rounded p-3"
            type="text"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Datum</label>
          <input
            onChange={(e) => {
              setDate(e.target.value);
            }}
            value={date}
            className="border border-sky-700 rounded p-3"
            type="text"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Tid för samling</label>
          <input
            onChange={(e) => {
              setTime(e.target.value);
            }}
            value={time}
            className="border border-sky-700 rounded p-3"
            type="text"
          />
        </div>
        <div className="flex flex-col py-2">
          <label className="p-2 font-medium">Samlingsplats</label>
          <input
            onChange={(e) => {
              setPlace(e.target.value);
            }}
            value={place}
            className="border border-sky-700 rounded p-3"
            type="text"
          />
        </div>

        <button
          onClick={handleSubmit}
          className="border disabled:bg-gray-400  border-sky-300 bg-sky-800 bg-opacity-75 hover:bg-sky-900 hover:bg-opacity-75 w-full p-4 my-2 text-white rounded"
        >
          Skapa jakt
        </button>
      </form>
      <Modal
        open={isDialogOpen}
        handleCloseDialog={() => {
          setIsDialogOpen(false);
        }}
        text="En ny jakt är skapad."
        header="Ny jakt skapad"
        buttonText="OK"
      />
    </div>
  );
};

export default CreateHunt;
