import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getIdTokenResult,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userProfileData, setUserProfileData] = useState({});
  const [token, setToken] = useState({});

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const getUserProfile = (uid) => {
    const docRef = doc(db, "users", uid);
    return getDoc(docRef).then((res) => {
      setUserProfileData({ uid: uid, ...res.data() });
      return res.data();
    });
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password).then((auth) => {
      getUserProfile(auth.user.uid);
    });
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        currentUser.getIdTokenResult().then((getIdTokenResult) => {
          //console.log(getIdTokenResult.claims);
          currentUser.admin = getIdTokenResult.claims.admin;
          // const claims = getIdTokenResult.claims;
          // setToken(claims);
          setUser(currentUser);
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        createUser,
        user,
        userProfileData,
        //token,
        logout,
        signIn,
        getUserProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
